<template>
<div class="search-box">
    <i class="ez-icon-search"></i>
    <input type="text" :placeholder="placeholder" v-model="query" @keyup="search">
</div>
</template>

<script>
    export default {
        name: "SearchBox",
        props: {
            source: Array,
            onSearch: Function,
            fields: Array,
            mapProperty: String,
            placeholder:String
        },

        data() {
            return{
                query: ''
            }
        },

        methods: {

            search() {
                if ( this.query < 1 ) {
                    if ( this.onSearch ) this.onSearch(this.source);
                    return ;
                }

                let results = [];

                for ( const field of this.fields ) {
                    if ( this.mapProperty && this.mapProperty.length > 0 )
                        results = results.concat(
                            this.source.filter(
                                item => item[this.mapProperty][field]
                                    && item[this.mapProperty][field].toLowerCase().includes(this.query.toLowerCase())
                            )).unique();
                    else
                        results = results.concat(
                            this.source.filter(
                                item => item[field]
                                    && item[field].toLowerCase().includes(this.query.toLowerCase())
                            )).unique();
                }

                if ( this.onSearch ) this.onSearch(results);
            }
        }
    }
</script>

<style scoped lang="scss">

    .search-box {
        --bg : #{color(bg-fill)};

        height: 35px;
        width: 100%;
        border: 1px solid #ced4da;
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        background: var(--bg);
        i {
            color:color(border);
            height: 100%;
            width: 35px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        input {
            background: var(--bg);
            border: none;
            flex: 1;
            font-size: .9rem;
        }
    }

</style>
